import cx from 'classnames';
import styles from './index.module.css';
const Select = ({
  className,
  children,
  size,
  color,
  ...props
}) => <select className={cx(styles.root, styles[color], styles[size], className)} {...props}>
    {children}
  </select>;
export default Select;