import { UserProvider } from '@auth0/nextjs-auth0/client';
import Fonts from './fonts';
const Provider = ({
  children
}) => <UserProvider>
    <Fonts>
      <div id='modal' />
      <div id='dropzonePortal' />
      {children}
    </Fonts>
  </UserProvider>;
export default Provider;