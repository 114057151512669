import cx from 'classnames';
import _ from 'lodash';
import styles from './index.module.css';
const Spinner = ({
  size = 24,
  color = 'white',
  className
}) => <div className={cx(styles['lds-ring'], className)} style={{
  width: size,
  height: size
}}>
    {_.times(5, i => <div key={i} style={{
    width: size,
    height: size,
    borderWidth: size / 6,
    borderColor: `${color} transparent transparent transparent`
  }} />)}
  </div>;
export default Spinner;