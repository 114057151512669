import cx from 'classnames';
import TooltipWrapper from '../tooltip-wrapper';
import styles from './index.module.css';
const InfoIcon = ({
  text,
  color,
  anchor,
  className,
  style
}) => <TooltipWrapper className={cx(styles.infoIcon, styles[color], className)} text={text} anchor={anchor} style={style}>
    <span className={styles.infoIconI}>i</span>
  </TooltipWrapper>;
export default InfoIcon;