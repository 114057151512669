import cx from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
const ControlledTextarea = props => {
  const {
    value,
    onChange,
    ...rest
  } = props;
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);
  const handleChange = e => {
    setCursor(e.target.selectionStart);
    if (onChange) onChange(e);
  };
  return <textarea ref={ref} value={value} onChange={handleChange} {...rest} />;
};
const TextArea = forwardRef(({
  className,
  children,
  size,
  color,
  ...props
}, ref) => props.value ? <ControlledTextarea className={cx(styles.root, styles[color], styles[size], className)} {...props} ref={ref}>
        {children}
      </ControlledTextarea> : <textarea className={cx(styles.root, styles[color], styles[size], className)} {...props} ref={ref}>
        {children}
      </textarea>);
export default TextArea;