'use client';

import NextImage from 'next/image';
import env from '~/lib/env';
const normalizeSrc = src => src[0] === '/' ? src.slice(1) : src;
const cloudinaryLoader = ({
  src,
  width,
  quality
}) => {
  const params = ['f_auto', 'c_limit', `w_${width},q_${quality || 'auto'}`];
  if (src.match(/^\//)) return src;
  return `https://res.cloudinary.com/${env.CLOUDINARY_CLOUD_NAME}/image/fetch/${params.join(',')}/${normalizeSrc(src)}`;
};
const Image = props => <NextImage loader={cloudinaryLoader} {...props} />;
export default Image;