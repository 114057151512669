import cx from 'classnames';
import React from 'react';
import styles from './index.module.css';
const Checkbox = React.forwardRef(({
  className,
  prefixLabel,
  suffixLabel,
  color,
  ...props
}, ref) => <label className={cx(styles.root, className, styles[color])}>
      {prefixLabel && <span className={styles.prefixLabel}>{prefixLabel}</span>}
      <input {...props} ref={ref} type='checkbox' />
      {suffixLabel && <span className={styles.suffixLabel}>{suffixLabel}</span>}
    </label>);
export default Checkbox;