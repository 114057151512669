import cx from 'classnames';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import styles from './index.module.css';
const ModalContainer = ({
  children,
  headerChildren,
  isOpen,
  onClose = () => {},
  className,
  bgColor
}) => {
  useHotkeys('esc', onClose);
  const inner = <div className={cx(styles.root, styles[bgColor])} id='modal-container' data-isclosetarget onClick={e => e.target.dataset.isclosetarget && onClose()}>
      <header className={styles.header} data-isclosetarget>
        {headerChildren}
        <div className={styles.imageModalClose} onClick={onClose}>
          ×
        </div>
      </header>
      <div className={styles.inner} data-isclosetarget>
        <div className={cx(styles.innerWrapper, className)} data-isclosetarget>
          {children}
        </div>
      </div>
    </div>;
  if (typeof window === 'undefined' && isOpen) return inner;
  if (!isOpen || !document.getElementById('modal')) return null;
  return ReactDOM.createPortal(inner, document.getElementById('modal'));
};
export default ModalContainer;