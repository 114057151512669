import cx from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import Spinner from '~/views/components/spinner';
import TooltipWrapper from '~/views/components/tooltip-wrapper';
import Tour from '~/views/components/tour';
import ImageUploadIcon from './image-upload.svg';
import styles from './index.module.css';
export const DropzoneCta = ({
  title
}) => ReactDOM.createPortal(<div className={styles.dropCta}>
      <small>Drop file to…</small>
      <ImageUploadIcon />
      {title || 'Upload'}
    </div>, document.getElementById('dropzonePortal') || document.body);
const ImageUploadButton = ({
  onUpload,
  isImageOnly,
  className,
  dropzoneSize = 'm',
  tooltipAnchor,
  title,
  isClickOnly = false
}) => {
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const {
    getRootProps,
    getInputProps,
    isDragActive: isDropzonDragActive
  } = useDropzone({
    accept: isImageOnly ? 'image/*' : 'image/*, video/*',
    maxFiles: 1,
    onDrop: async acceptedFiles => {
      setIsUploading(true);
      try {
        if (onUpload) await onUpload(acceptedFiles);
      } catch (err) {
        setError(err);
      }
      setIsUploading(false);
    }
  });
  const isDragActive = isDropzonDragActive && !isClickOnly;
  const toggleBodyClass = () => document.body.classList.toggle('isFileOverDropzone', isDragActive);
  useEffect(() => {
    toggleBodyClass();
    return toggleBodyClass;
  }, [isDragActive]);
  return <TooltipWrapper text={<>
          {title && <strong className={styles.tooltipHeader}>{title}</strong>}
          Drag & drop, or click to browse, an image (max. 10 MB)&nbsp;
          {!isImageOnly && ' or video file (max. 100 MB)' || ''}
        </>} anchor={tooltipAnchor} className={cx(styles.root, isDragActive && styles.isDragActive, className)} {..._.omit(getRootProps(), 'ref')}>
      {isDragActive && <DropzoneCta title={title} />}
      {error && <Tour className={styles.errorTour}>{error.toString()}</Tour>}
      {!isClickOnly && <div className={cx(styles.dropzone, styles[dropzoneSize])} />}
      <input {...getInputProps()} />
      {isUploading && <Spinner size={18} color='var(--color-gray-100)' /> || <ImageUploadIcon />}
    </TooltipWrapper>;
};
export const DropzoneContainer = ({
  children,
  className
}) => {
  const {
    getRootProps,
    isDragActive
  } = useDropzone();
  return <div className={cx(isDragActive && 'isFileOverViewport', className)} {...{
    ...getRootProps({
      onClick: e => e.stopPropagation()
    }),
    tabIndex: undefined
  }}>
      {children}
    </div>;
};
export default ImageUploadButton;