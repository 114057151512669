import NextHead from 'next/head';
import env from '~/lib/env';
const Head = ({
  children,
  title = 'SendingStone - Play tabletop RPGs anywhere'
}) => <NextHead>
    <title>{title}</title>
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <link rel='canonical' href={env.APP_URL} />
    <meta name='robots' content='index, follow' />
    <meta property='og:type' content='article' />
    <meta property='og:title' content='SendingStone' />
    <meta property='og:description' content='Play tabletop role-playing games like Dungeons & Dragons with your friends online. SendingStone is a video conferencing experience worthy of fantasy RPGs.' />
    <meta property='og:image' content={`${env.APP_URL}/og-image.jpg`} />
    <meta property='og:url' content={env.APP_URL} />
    <meta property='og:site_name' content='SendingStone' />
    <meta name='description' content='Play tabletop role-playing games like Dungeons & Dragons with your friends online. SendingStone is a video conferencing experience worthy of fantasy RPGs.' />
    <meta name='twitter:title' content='SendingStone' />
    <meta name='twitter:description' content='Play tabletop role-playing games like Dungeons & Dragons with your friends online. SendingStone is a video conferencing experience worthy of fantasy RPGs.' />
    <meta name='twitter:image' content={`${env.APP_URL}/og-image.jpg`} />
    <meta name='twitter:site' content='@sendingstoneapp' />
    <meta name='twitter:creator' content='@sendingstoneapp' />
    <link rel='icon' type='image/svg+xml' href='/favicons/favicon-16.svg' sizes='16x16' />
    <link rel='icon' type='image/svg+xml' href='/favicons/favicon-16.svg' sizes='32x32' />
    <link rel='icon' type='image/svg+xml' href='/favicons/favicon-64.svg' sizes='64x64' />
    <link rel='icon' type='image/svg+xml' href='/favicons/favicon-64.svg' sizes='96x96' />
    {typeof window === 'undefined' && env.SEGMENT_ID && <script dangerouslySetInnerHTML={{
    __html: `
      !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
      analytics.load("${env.SEGMENT_ID}");
      analytics.page();
      }}();`
  }} />}
    {children}
  </NextHead>;
export default Head;