'use client';

import cx from 'classnames';
import { useUserAgent } from 'next-useragent';
import { useEffect, useState } from 'react';
const UserAgentClass = ({
  children
}) => {
  const [klass, setKlass] = useState();
  const ua = useUserAgent();
  useEffect(() => {
    setKlass(cx({
      userAgent: true,
      ...ua,
      isIpad: ua.isMac && 'ontouchstart' in window
    }));
  }, []);
  return <div className={klass}>{children}</div>;
};
export default UserAgentClass;