if (typeof window === 'undefined') require('dotenv').config()

const defaults = {
  AGORA_APP_ID: '4b3d15d7089f4d1aa0aa5cbf47419f7f',
  APP_URL: 'http://localhost:3000',
  AUTH0_BASE_URL: 'http://localhost:3000',
  AUTH0_CLIENT_ID: 'F7FujX2HtQBbjBSSEfNqlsSRIJrO0UUX',
  AUTH0_ISSUER_BASE_URL: 'https://sendingstone.us.auth0.com',
  CLOUDINARY_CLOUD_NAME: 'dcyzl5gnv',
  CLOUDINARY_UPLOAD_PRESET: 'ctca2ea4',
  DATABASE_URL:
    'postgresql://astralprojection:@localhost:5432/astralprojection',
  DEFAULT_MIC_VOLUME: 200,
  NODE_ENV: 'development',
  PORT: 3000,
  STRIPE_PUBLISHABLE_KEY:
    'pk_test_51IS5iDCHTQmnRZzAx6XtBNx7VeqII5ykaMIWhWJTXVJkK2tmD1oHds8CzdTtFdNkf9Inyzx59IuDF3QB3eimuRtg00xzTqJAYu',
  TABLETOP_SYNC_DEBOUNCE: 1000,
  TABLETOP_ZIP_DEBOUNCE: 5000,
  REDIS_URL: '127.0.0.1:6379',
  SENTRY_DSN:
    'https://06a9cd9aa57d4e19842ac8a7e68c9bc1@o501237.ingest.us.sentry.io/5582144',
  IS_TABLETOP_ONLY: false,
  CHAT_PAGE_SIZE: 10,
  CURSOR_THROTTLE: 500,
}

const env = {
  AGORA_APP_ID: process.env.NEXT_PUBLIC_AGORA_APP_ID,
  APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  AUTH0_BASE_URL: process.env.AUTH0_BASE_URL,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
  AUTH0_ISSUER_BASE_URL: process.env.AUTH0_ISSUER_BASE_URL,
  AUTH0_SECRET: process.env.AUTH0_SECRET,
  CLOUDINARY_CLOUD_NAME: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET: process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_UPLOAD_URL: process.env.CLOUDINARY_UPLOAD_URL,
  DATABASE_URL: process.env.DATABASE_URL,
  DEEPAR_KEY: process.env.NEXT_PUBLIC_DEEPAR_KEY,
  DEFAULT_MIC_VOLUME: Number(process.env.NEXT_PUBLIC_DEFAULT_MIC_VOLUME),
  NODE_ENV: process.env.NODE_ENV || process.env.NEXT_PUBLIC_NODE_ENV,
  PORT: Number(process.env.PORT),
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  TABLETOP_SYNC_DEBOUNCE: Number(
    process.env.NEXT_PUBLIC_TABLETOP_SYNC_DEBOUNCE,
  ),
  TABLETOP_ZIP_DEBOUNCE: Number(process.env.NEXT_PUBLIC_TABLETOP_ZIP_DEBOUNCE),
  REDIS_URL: process.env.REDIS_URL,
  SEGMENT_ID: process.env.SEGMENT_ID,
  SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
  BROADCAST_ID_SALT: process.env.BROADCAST_ID_SALT,
  DEEPAI_API_KEY: process.env.DEEPAI_API_KEY,
  CLOUDINARY_URL: process.env.CLOUDINARY_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  IS_TABLETOP_ONLY: Boolean(process.env.NEXT_PUBLIC_IS_TABLETOP_ONLY),
  CHAT_PAGE_SIZE: Number(process.env.CHAT_PAGE_SIZE),
  CURSOR_THROTTLE: Number(process.env.NEXT_PUBLIC_CURSOR_THROTTLE),
}

const merged = Object.entries(env).reduce((acc, [key, val]) => {
  const v = val || defaults[key]
  return v ? Object.assign(acc, { [key]: v }) : acc
}, {})

for (const key in merged) process.env[key] ||= merged[key]

module.exports = merged
