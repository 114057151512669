import cx from 'classnames';
import { Proza_Libre as Proza } from 'next/font/google';
import localFont from 'next/font/local';
const benguiat = localFont({
  src: './benguiat-pro-bold-condensed.woff2',
  variable: '--font-family-benguiat'
});
const proza = Proza({
  weight: ['400', '600', '700'],
  style: ['normal', 'italic'],
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-family-proza'
});
export default (({
  children
}) => <main className={cx(proza.className, proza.variable, benguiat.variable)}>
    {children}
  </main>);