import cx from 'classnames';
import components from '~/views/styles/components.module.css';
import styles from './index.module.css';
const TooltipWrapper = props => {
  const {
    children,
    text,
    className,
    anchor,
    color,
    ...otherProps
  } = props;
  return <div className={cx(className, styles.wrapper)} {...otherProps}>
      {children}
      <div className={cx(components.tooltipArrow, styles.root, anchor && styles[anchor + 'Anchor'], color && styles['color-' + color])}>
        {text}
      </div>
    </div>;
};
export default TooltipWrapper;