import cx from 'classnames';
import React from 'react';
import Spinner from '../spinner';
import styles from './index.module.css';
const Button = React.forwardRef(({
  className,
  children,
  color,
  variant,
  isLoading,
  size,
  href,
  ...props
}, ref) => {
  const fullProps = {
    ...props,
    ref,
    href,
    className: cx(styles.root, className, styles[color], styles[size], styles[variant])
  };
  const inner = isLoading && <Spinner size={{
    l: 24
  }[size] || 16} className={styles.spinner} color={variant === 'outlined' ? 'var(--color-gray-600)' : {
    white: 'var(--color-gray-900)',
    gray200: 'var(--color-gray-900)',
    gray800: 'var(--color-gray-800)',
    grayAlpha900: 'var(--color-gray-800)'
  }[color] || 'var(--color-gray-100)'} /> || children;
  if (href) return <a {...fullProps}>{inner}</a>;
  return <button {...fullProps}>{inner}</button>;
});
export default Button;