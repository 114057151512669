import cx from 'classnames';
import { forwardRef } from 'react';
import { useEffect, useRef, useState } from 'react';
import Spinner from '~/views/components/spinner';
import styles from './index.module.css';
const ControlledInput = props => {
  const {
    value,
    onChange,
    ...rest
  } = props;
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);
  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);
  const handleChange = e => {
    setCursor(e.target.selectionStart);
    if (onChange) onChange(e);
  };
  return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};
const Input = forwardRef(({
  className,
  containerClassName,
  isLoading,
  error,
  type,
  required,
  color,
  size,
  ...props
}, ref) => <div className={cx(styles.container, styles[color], styles[size], containerClassName)}>
      {props.value ? <ControlledInput {...props} ref={ref} className={cx(styles.root, className)} disabled={isLoading} type={type} required={required} /> : <input {...props} ref={ref} className={cx(styles.root, className)} disabled={isLoading} type={type} required={required} />}
      {error && <div className={styles.error}>
          !<div className={styles.tooltip}>{error}</div>
        </div>}
      {isLoading && <Spinner size={16} className={styles.spinner} color='var(--color-gray-700)' />}
    </div>);
export default Input;