import { useState } from 'react';
import { createConfirmation } from 'react-confirm';
import Button from '../button';
import ModalContainer from '../modal-container';
import styles from './index.module.css';
const Dialog = ({
  dispose,
  resolve,
  confirmation
}) => {
  const [isShowing, setIsShowing] = useState(true);
  const dismiss = () => {
    setIsShowing(false);
    dispose();
  };
  const proceed = value => {
    setIsShowing(false);
    resolve(value);
  };
  if (!isShowing) return null;
  return <ModalContainer isOpen={isShowing} onClose={dismiss}>
      <div className={styles.inner}>
        <h1>Are you sure?</h1>
        <p>{confirmation}</p>
        <div className={styles.buttons}>
          <Button onClick={dismiss} variant='outlined' color='white'>
            Cancel
          </Button>
          <Button onClick={proceed} color='red'>
            Confirm
          </Button>
        </div>
      </div>
    </ModalContainer>;
};
const confirmer = createConfirmation(Dialog);
export default (confirmation => confirmer({
  confirmation
}));