import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import components from '~/views/styles/components.module.css';
import styles from './index.module.css';
const Tour = ({
  children,
  className,
  closeIf,
  openIf,
  isInitVisible = true,
  isControlledVisible,
  setIsControlledVisible
}) => {
  const [isLocalVisible, setLocalIsVisible] = useState(isInitVisible);
  const isVisible = isControlledVisible ?? isLocalVisible;
  const setIsVisible = setIsControlledVisible ?? setLocalIsVisible;
  useEffect(() => {
    if (closeIf) setIsVisible(false);
  }, [closeIf]);
  useEffect(() => {
    if (openIf) setIsVisible(true);
  }, [openIf]);
  useHotkeys('esc', () => setIsVisible(false));
  return isVisible && <div className={cx(className, styles.tour, components.tooltipArrow)} onClick={e => {
    e.stopPropagation();
    setIsVisible(false);
  }}>
        {children}
        <button className={styles.tourClose}>×</button>
      </div>;
};
export default Tour;